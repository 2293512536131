import { Location } from '@reach/router';
import { colors } from 'component-library/styles/colors';
import { Link as GatsbyLink } from 'gatsby';
import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { baseLinkStyles } from './shared';

interface InlineLinkProps {
  to?: string;
  href?: string;
  target?: string;
}

const inlineLinkStyles = `
    border-bottom: 1px solid ${colors.navyBlue};
`;

const StyledInlineLink = styled.a`
  ${inlineLinkStyles}
  ${baseLinkStyles}
`;

const StyledInlineInternalLink = styled(GatsbyLink)`
  ${inlineLinkStyles}
  ${baseLinkStyles}
`;

export const InlineLink: FC<PropsWithChildren<InlineLinkProps>> = ({
  to,
  href,
  children,
  ...rest
}) => {
  const isExternalLink = to?.includes('https') || to?.includes('mailto');

  if (href) {
    return (
      <StyledInlineLink href={href} {...rest}>
        {children}
      </StyledInlineLink>
    );
  }

  return (
    <Location>
      {({ location }) =>
        isExternalLink ? (
          <StyledInlineLink
            href={to + location.search}
            target="_blank"
            {...rest}
          >
            {children}
          </StyledInlineLink>
        ) : (
          <StyledInlineInternalLink to={to + location.search} {...rest}>
            {children}
          </StyledInlineInternalLink>
        )
      }
    </Location>
  );
};
