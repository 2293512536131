import { colors } from 'component-library/styles/colors';

export const baseLinkStyles = `
    cursor: pointer;
    color: ${colors.navyBlue};
    font-family: Inter;
    line-height: 166%;

    &:hover {
        color: ${colors.forestGreen};
    }

    &:active {
        color: ${colors.grey};
    }
`;
