import { Flex } from 'component-library/components/layout/Flex';
import { Body, bodyStyles } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import { colors } from 'component-library/styles/colors';
import { device } from 'component-library/styles/device';
import styled from 'styled-components';

export const Paragraph = styled(Body).attrs({ variant: 4 })``;

export const H1 = styled(Subheading).attrs({
    medium: true,
    variant: 1,
    marginBottom: 20,
})``;

export const H2 = styled(Subheading).attrs({
    variant: 2,
    marginBottom: 10,
})``;

export const H3 = styled(Body).attrs({
    variant: 2,
})``;

export const Strong = styled(Body)`
  font-weight: bold;
`;

export const Ul = styled.ul`
  list-style: revert;
  padding: revert;
  margin: revert;
`;

export const Ol = styled.ol`
  list-style: revert;
  padding: revert;
  margin: revert;
`;

export const Li = styled.li.attrs({ variant: 4 })`
  ${bodyStyles}
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    border: 1px solid ${colors.grey};
`;

export const Th = styled(H3).attrs({ as: 'th' })`
    display: table-cell;
    font-weight: bold;
    border: 1px solid ${colors.grey};
    padding: 10px;
    background-color: ${colors.grey};
`;

export const Td = styled(Paragraph).attrs({ as: 'td' })`
    display: table-cell;
    border: 1px solid ${colors.grey};
    padding: 10px;
`;

export const LegalSection = styled(Flex).attrs({ column: true })`
    padding: 25px 0;
    border-bottom: 1px dotted ${colors.grey};
`;

export const LegalWrapper = styled.div`
    margin: 10px auto;
    padding: 10px 20px;
    max-width: 1380px;

    ${`@media ${device.lg}`} {
        padding: 40px;
    }
`;
