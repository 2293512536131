import Layout from 'common/layouts/Layout';
import { InlineLink } from 'common/links/InlineLink';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import {
  H2,
  LegalSection,
  LegalWrapper,
  Li,
  Paragraph,
  Ul,
} from 'components/pages/legal/shared';
import { SEO } from 'components/SEO';
import React from 'react';

const ElectronicDisclosureAndConsent = () => (
  <Layout>
    <Navigation />
    <PageWrapper>
      <LegalWrapper>
        <LegalSection>
          <H2 medium>Electronic Disclosure and Consent</H2>
          <Paragraph marginBottom={20}>
            In this agreement, &quot;we,&quot; &quot;us,&quot; &quot;our,&quot;
            and &quot;RETIRABLE&quot; mean Retirable, Inc. &quot;You&quot; and
            &quot;your&quot; refer to the business entity signing up for a
            RETIRABLE account or using a RETIRABLE product.
            &quot;Communications&quot; means disclosures, notices, agreements,
            fee schedules, privacy policies, statements, records, documents, and
            other information we provide to you, or that you sign and submit or
            agree to at our request.
          </Paragraph>
          <Paragraph>
            RETIRABLE is dedicated to providing the best online banking
            experience possible, including providing information to you
            electronically. By accepting this agreement, you agree that you are
            willing and able to receive Communications in electronic form, and
            consent to receive Communications in electronic form. If you do not
            give your consent to receive Communications in electronic and not
            paper form, you may not open a RETIRABLE account. If you withdraw
            your consent to electronic Communications at any point after
            providing consent hereunder, we reserve the right to terminate your
            Account.
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <Paragraph medium marginBottom={20}>
            Delivery Methods
          </Paragraph>
          <Paragraph>
            We will deliver Communications to you electronically, either through
            the RETIRABLE website{' '}
            <InlineLink href="https://app.retirable.com">
              https://app.retirable.com
            </InlineLink>
            ; text or SMS messages (&quot;Text Messages&quot;); or through
            electronic mail (&quot;E-mail&quot;). If we do not deliver
            Communications to you through the above manners, we will tell you
            where you can go to receive such Communications.
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <Paragraph medium marginBottom={20}>
            Hardware and Software Requirements
          </Paragraph>
          <Paragraph>
            To receive and retain electronic Communications from RETIRABLE, you
            will need the following:
            <Ul>
              <Li>
                A computer or mobile device with an operating system that
                supports everything below;
              </Li>
              <Li>An internet connection;</Li>
              <Li>
                A current version of a web browser that we support, including:
                Edge version 42 or higher, Firefox version 62 or higher, Safari
                version 12 or higher, or Chrome version 69 or higher;
              </Li>
              <Li>A hard drive or other method of storing data;</Li>
              <Li>A valid, active email address; and</Li>
              <Li>A current version of a program that displays PDF files.</Li>
              We may update these requirements periodically in order to maintain
              our ability to provide electronic Communications; if these
              requirements change in a substantial way, we will notify you of
              the changes.
            </Ul>
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <Paragraph medium marginBottom={20}>
            Updating Contact Information
          </Paragraph>
          <Paragraph>
            It is your responsibility to maintain accurate and current contact
            information so that we may send you electronic Communications. You
            can update your email address and other contact information by
            logging into your online account at{' '}
            <InlineLink href="https://app.retirable.com">
              https://app.retirable.com
            </InlineLink>
            , in the &quot;Profile&quot; section, or by emailing us at{' '}
            <InlineLink href="mailto:support@retirable.com">
              support@retirable.com
            </InlineLink>
            .
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <Paragraph medium marginBottom={20}>
            Requesting Paper Copies
          </Paragraph>
          <Paragraph>
            If we choose to make paper copies of certain electronic
            Communications available, we do not create any obligation to do so
            at other times. You may obtain a paper copy of an electronic
            Communication by printing it or by requesting we mail a paper copy.
            You may make requests for paper copies by emailing us at{' '}
            <InlineLink href="mailto:support@retirable.com">
              support@retirable.com
            </InlineLink>
            .
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <Paragraph medium marginBottom={20}>
            Communications in Writing
          </Paragraph>
          <Paragraph>
            We are required by law to give you certain information in writing.
            All Communications delivered to you in either electronic or paper
            format will be taken as information delivered &quot;in
            writing.&quot;
          </Paragraph>
        </LegalSection>
        <LegalSection marginBottom={56}>
          <Paragraph medium marginBottom={20}>
            Withdrawing Consent
          </Paragraph>
          <Paragraph>
            You have the right to withdraw your consent to this agreement at any
            time. Withdrawing consent will terminate your RETIRABLE account,
            including access to our Website and Mobile App. A balance refund
            check may be issued to you upon account termination in accordance
            with this Agreement. You may withdraw consent by emailing us at{' '}
            <InlineLink href="mailto:support@retirable.com">
              support@retirable.com
            </InlineLink>
            .
          </Paragraph>
        </LegalSection>
      </LegalWrapper>
    </PageWrapper>
  </Layout>
);

export default ElectronicDisclosureAndConsent;

export const Head = () => (
  <SEO
    title="Electronic Disclosure & Consent — Retirable"
    description="View electronic disclosure and consent forms for Retirable."
  />
);
